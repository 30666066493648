<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.PUBLIC_FOLDERS')">
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-treeview
              :items="folders"
              item-key="folderid"
              :search="search"
              :filter="filter"
              :open="opened"
              open-on-click
              open-all
              dense
              hoverable
            >
              <template v-slot:prepend="{ item }">
                <v-icon small
                  v-if="item.container === 'IPF.Appointment'"
                >flaticon-event-calendar-symbol</v-icon>
                <v-icon small
                  v-else-if="item.container === 'IPF.Contact'"
                >flaticon2-user</v-icon>
                <v-icon small
                  v-else-if="item.container === 'IPF.Stickynote'"
                >flaticon2-notepad</v-icon>
                <v-icon small
                  v-else-if="item.container === 'IPF.Task'"
                >flaticon-list</v-icon>
                <v-icon small
                  v-else
                >flaticon2-mail</v-icon>
              </template>
              <template v-slot:label="{ item }">
                {{ item.name }}
                <v-icon
                  small
                  color="blue"
                  @click="showCreateFolder(item)"
                  v-b-tooltip.hover
                  :title="$t('COMMON.ADD')"
                  class="mx-3"
                >
                flaticon-plus
                </v-icon>
              </template>
              <template v-slot:append="{ item }">
                <v-icon
                  v-if="rootFolderId !== item.folderid"
                  small
                  color="blue"
                  @click="showEditFolder(item)"
                  v-b-tooltip.hover
                  :title="$t('COMMON.EDIT')"
                  class="mx-3"
                >
                edit
                </v-icon>
                <v-icon
                  v-if="rootFolderId !== item.folderid"
                  small
                  color="blue"
                  @click="saveActiveItem(item)"
                  v-b-modal.confirm-delete
                  v-b-tooltip.hover
                  :title="$t('COMMON.DELETE')"
                  class="mx-3"
                >
                delete
                </v-icon>
              </template>
            </v-treeview>

            <!-- ----------add folder modal------------- -->
            <b-modal id="addFolder" ref="addFolder" :title="$t('PUBLIC_FOLDERS.FOLDER_DETAILS')" size="lg">
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.FOLDER_NAME") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      v-model="$v.addFolderForm.pfname.$model"
                      :state="validateState('pfname')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-pfname-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.CONTAINER") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addFolderForm.pftype"
                      :options="folderTypeData"
                      :disabled="!isCreateModal"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="!isSubfolder">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.QUOTA") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      v-model="$v.addFolderForm.quota.$model"
                      type="number"
                      :state="validateState('quota')"
                      aria-describedby="input-quota-feedback"
                      step="0.1"
                      min="0.1"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.addFolderForm.quota.required"
                    >
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.addFolderForm.quota.quotaZeroValidator"
                    >
                      {{ $t("VALIDATION.QUOTA_ZERO") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback id="input-quota-feedback">
                      {{
                        $t("VALIDATION.QUOTA_FIELD", { num: maxQuotaValue() })
                      }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="!isSubfolder && !isCreateModal">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.USAGE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      v-model="addFolderForm.usage"
                      disabled
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row v-if="!isCreateModal">
                  <b-button variant="outline-secondary" v-on:click="showPermissions()">
                    {{ $t("USERS.PERM.PERMISSIONS") }}
                  </b-button>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('addFolder')">
                  {{ $t("COMMON.CLOSE") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-if="isCreateModal"
                  v-on:click="addFolder()"
                >
                  {{ $t("COMMON.ADD") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-if="!isCreateModal"
                  v-on:click="updateFolderInfo()"
                >
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------add folder modal------------- -->

            <!-- ----------permissions folder modal------------- -->
            <b-modal id="folderPermissions" ref="folderPermissions" :title="$t('USERS.PERM.PERMISSIONS')" size="lg">
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="12" v-if="permissions.length > 0">
                    <b-list-group>
                      <b-list-group-item href="#" v-for="(perm, index) in permissions"
                        :key="index"
                        :active="index === selectedIndex"
                        v-on:click="showPermissionsForUser(index)"
                      >
                        {{ perm.username }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col sm="12" v-else>
                    <b-list-group>
                      <b-list-group-item>
                        {{ $t("PUBLIC_FOLDERS.NO_OWNERS") }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="12">
                    <b-button-toolbar style="justify-content: flex-end;">
                      <b-button 
                        variant="primary" 
                        class="mx-1" 
                        v-on:click="showAddOwner()"
                      >
                        {{ $t("COMMON.ADD") }}
                      </b-button>
                      <b-button
                        variant="outline-secondary"
                        class="mx-1"
                        v-on:click="removeUserPermissions()"
                      >
                        {{ $t("COMMON.REMOVE") }}
                      </b-button>
                    </b-button-toolbar>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.PROFILE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="permissionsForm.permissions"
                      :options="permissionTypeData"
                      v-on:change="showRolePermissions"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="6">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.READ") }}</label>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="permissionsReadSelected"
                        :options="permissionsReadData"
                        stacked
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.WRITE") }}</label>
                    <b-form-checkbox-group
                      v-model="permissionsWriteSelected"
                      :options="permissionsWriteData"
                      stacked
                      v-on:change="showValue(permissionsWriteSelected)"
                    ></b-form-checkbox-group>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="6">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.DELETE_ITEMS") }}</label>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="permissionsDeleteSelected"
                        :options="permissionsDeleteData"
                        stacked
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <label class="mb-0">{{ $t("PUBLIC_FOLDERS.OTHER") }}</label>
                    <b-form-checkbox-group
                      v-model="permissionsOtherSelected"
                      :options="permissionsOtherData"
                      stacked
                    ></b-form-checkbox-group>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('folderPermissions')">
                  {{ $t("COMMON.CLOSE") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="saveUserPermissions()"
                >
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------permissions folder modal------------- -->

            <!-- ----------add user permissions modal------------- -->
            <b-modal
              id="addOwner"
              :title="$t('PUBLIC_FOLDERS.ADD_OWNER')"
              ref="addOwner"
            >
              <b-row>
                <b-col>
                  <label class="mb-0">{{ $t("PUBLIC_FOLDERS.OWNERS") }}</label>
                  <multi-select :options="allDomainUsers"
                                :selected-options="selectedFolderOwners"
                                :placeholder="$t('COMMON.SEARCH')"
                                @select="onSelect1">
                  </multi-select>
                </b-col>
              </b-row>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('addOwner')"
                >
                  {{ $t("COMMON.CLOSE") }}
                </b-button>
                <b-button variant="primary" v-on:click="addOwners">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------add user permissions modal------------- -->

            <!-- ----------delete folder modal------------- -->
            <b-modal
              id="confirm-delete"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: activeItem.name }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteFolder">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete folder modal------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, minLength } from "vuelidate/lib/validators";
import { MultiSelect  } from 'vue-search-select';

const usernameRegex = helpers.regex("alpha", /^[0-9a-zA-Z\-_.+:]+$/);
function myCustomValidator(value) {
  return (
    value <= localStorage.limitQuota * 1 + this.addFolderForm.quota * 1
  ); // should return Boolean
}
function quotaZeroValidator() {
  return (
    0 <=
    localStorage.limitQuota * 1 +
      this.addFolderForm.currentQuota * 1 -
      this.addFolderForm.quota * 1
  ); // should return Boolean
}
export default {
  mixins: [validationMixin],
  name: "folders",
  components: {
    KTPortlet,
    MultiSelect,
  },
  data() {
    return {
      search: "",
      folders: [],
      opened: [],
      rootFolderId: "",
      activeItem: {},
      selectDomain: {
        selectedDomain: {},
        allDomains: [],
      },
      isCreateModal: false,
      addFolderForm: {
        folderid: "",
        domain: "",
        pfname: "",
        pftype: "",
        quota: 0,
        currentQuota: 0,
      },
      permissions: [],
      selectedUser: {},
      selectedIndex: 0,
      permissionsForm :{
        permissions: "",
        domain: "",
        member_id: "",
        folderid: "",
        action: "",
        token: "",
      },
      allDomainUsers: [],
      selectedFolderOwners: [],
      lastSelectItem1: {},
      caseSensitive: false,
      isSubfolder: false,
      permissionsReadSelected:'',
      permissionsWriteSelected:[],
      permissionsDeleteSelected:'',
      permissionsOtherSelected:[],
    };
  },
  computed: {
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    folderTypeData: function () {
      return [
        {text: this.$t("PUBLIC_FOLDERS.MAIL_AND_POST_ITEMS"), value: "IPF.Note"},
        {text: this.$t("PUBLIC_FOLDERS.CONTACT"), value: "IPF.Contact"},
        {text: this.$t("PUBLIC_FOLDERS.APPOINTMENT"), value: "IPF.Appointment"},
        {text: this.$t("PUBLIC_FOLDERS.STICKY_NOTE"), value: "IPF.Stickynote"},
        {text: this.$t("PUBLIC_FOLDERS.TASK"), value: "IPF.Task"},
      ];
    },
    permissionTypeData: function () {
      return [
        {text: this.$t("PUBLIC_FOLDERS.OWNER"), value: "2043"},
        {text: this.$t("PUBLIC_FOLDERS.PUBLISHING_EDITOR"), value: "1275"},
        {text: this.$t("PUBLIC_FOLDERS.EDITOR"), value: "1147"},
        {text: this.$t("PUBLIC_FOLDERS.PUBLISHING_AUTHOR"), value: "1179"},
        {text: this.$t("PUBLIC_FOLDERS.AUTHOR"), value: "1051"},
        {text: this.$t("PUBLIC_FOLDERS.NONEDITING_AUTHOR"), value: "1043"},
        {text: this.$t("PUBLIC_FOLDERS.REVIEWER"), value: "1025"},
        {text: this.$t("PUBLIC_FOLDERS.CONTRIBUTOR"), value: "1026"},
        {text: this.$t("PUBLIC_FOLDERS.NONE"), value: "0"},
      ];
    },
    permissionsReadData: function () {
      return [
        {text: this.$t("PUBLIC_FOLDERS.NONE"), value: "0"},
        {text: this.$t("PUBLIC_FOLDERS.FULL_DETAILS"), value: "1"},
      ];
    },
    permissionsWriteData: function () {
      return [
        {text: this.$t("PUBLIC_FOLDERS.CREATE_ITEMS"), value: "2"},
        {text: this.$t("PUBLIC_FOLDERS.CREATE_SUBFOLDERS"), value: "128"},
        {text: this.$t("PUBLIC_FOLDERS.EDIT_OWN"), value: "8"},
        {text: this.$t("PUBLIC_FOLDERS.EDIT_ALL"), value: "32"},
      ];
    },
    permissionsDeleteData: function() {
      return [
        {text: this.$t("PUBLIC_FOLDERS.NONE"), value: "1"},
        {text: this.$t("PUBLIC_FOLDERS.OWN"), value: "16"},
        {text: this.$t("PUBLIC_FOLDERS.ALL"), value: "80"},
      ];
    },
    permissionsOtherData: function() {
      return [
        {text: this.$t("PUBLIC_FOLDERS.FOLDER_OWNER"), value: "256"},
        {text: this.$t("PUBLIC_FOLDERS.FOLDER_CONTACT"), value: "512"},
        {text: this.$t("PUBLIC_FOLDERS.FOLDER_VISIBLE"), value: "1024"},
      ];
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata.filter(el => el.domaintype == 3);
        this.selectDomain.selectedDomain = this.selectDomain.allDomains[0];
        this.getPublicFolders(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addFolderForm: {
      pfname: {
        required,
        minLength: minLength(2),
        usernameRegex,
      },
      quota: {
        required,
        myCustomValidator,
        quotaZeroValidator,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.PUBLIC_FOLDERS") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addFolderForm[name];
      return $dirty ? !$error : null;
    },
    maxQuotaValue() {
      return (
        localStorage.limitQuota * 1 +
        this.addFolderForm.currentQuota * 1
      ).toFixed(2);
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    onSelect1(items, lastSelectItem) {
      this.selectedFolderOwners = items;
      this.lastSelectItem1 = lastSelectItem;
    },

    showValue(item) {
      item=item*1;
      return item;
    },

    /* ---------read item---------- */
    getPublicFolders(domain) {
      this.folders = [];
      postRequest({
        action: "getOEXPublicFoldersTree",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          if(res.returndata.name == "IPM_SUBTREE") {
            res.returndata.name = this.$t("MENU.PUBLIC_FOLDERS");
          }
          if (!res.returndata.children){
            res.returndata.children = [];
          }
          res.returndata.children = res.returndata.children.map((el) => {
            el.isFolder = true;
            el.quota = el.quota ? el.quota:0;
            return el;
          });
          this.folders = [res.returndata];
          this.rootFolderId = this.folders[0].folderid;
          this.opened = [this.rootFolderId];
        } else {
          this.folders = [];
          this.rootFolderId = "";
        }
      });
    },

    showCreateFolder(parent) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addFolderForm.domain = this.selectDomain.selectedDomain.domain;
      this.addFolderForm.folderid = parent.folderid;
      this.addFolderForm.pfname = "";
      this.addFolderForm.pftype = "IPF.Note";
      this.addFolderForm.currentQuota = 0;
      this.addFolderForm.quota = "";

      this.isSubfolder = (parent.folderid == this.rootFolderId ) ? false : true;
      this.isCreateModal = true;
      this.$refs["addFolder"].show();
    },

    addFolder(){
      postRequest({
        action: this.isSubfolder ? "addOEXPublicSubFolder" : "addOEXPublicFolder",
        token: localStorage.id_token,
        domain: this.addFolderForm.domain,
        folderid: this.addFolderForm.folderid,
        pfname: this.addFolderForm.pfname,
        pftype: this.addFolderForm.pftype,
        quota: this.addFolderForm.quota ? this.addFolderForm.quota : 0,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            this.$t("COMMON.WAIT_MESSAGE")
          );
          this.getPublicFolders(
            this.selectDomain.selectedDomain.domain
          );
          this.confirmModalClose("addFolder");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    showEditFolder(item){
      this.isCreateModal = false;
      this.isSubfolder = !item.isFolder;
      let quota = item.quota ? (item.quota/1000).toFixed(2) : 0;
      this.addFolderForm.domain = this.selectDomain.selectedDomain.domain;
      this.addFolderForm.folderid = item.folderid;
      this.addFolderForm.pfname = item.name;
      this.addFolderForm.pftype = item.container;
      this.addFolderForm.currentQuota = quota;
      this.addFolderForm.quota = quota;
      this.addFolderForm.usage = item.usage;
      this.saveActiveItem(item);
      this.$refs["addFolder"].show();
    },
    updateFolderInfo(){
      postRequest({
        action: "updateOEXPublicFolder",
        token: localStorage.id_token,
        domain: this.addFolderForm.domain,
        folderid: this.addFolderForm.folderid,
        name: this.addFolderForm.pfname,
        quota: this.addFolderForm.quota ? this.addFolderForm.quota * 1000 : 0,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getPublicFolders(
            this.selectDomain.selectedDomain.domain
          );
          this.$refs["addFolder"].hide();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    showPermissions(){
      postRequest({
        action: "getOEXPublicFolderPermissions",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        folderid: this.activeItem.folderid,
      }).then((res) => {
        if (res.returncode) {
          this.permissions = res.returndata;
          if (this.permissions.length){
            this.showPermissionsForUser(0);
          }
          this.$refs["folderPermissions"].show();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    showPermissionsForUser(index) {
      this.selectedIndex = index;
      this.permissionsForm.member_id = this.permissions[index].memberID;
      this.permissionsForm.domain = this.selectDomain.selectedDomain.domain;
      this.permissionsForm.folderid = this.activeItem.folderid;
      this.permissionsForm.permissions = this.permissions[index].permissions;
    },
    showRolePermissions() {
      this.permissionsReadSelected = '';
      this.permissionsReadData.forEach((el) => {
        if (parseInt(el.value) & parseInt(this.permissionsForm.permissions)){
          this.permissionsReadSelected = el.value;
        }
      }, this);
      this.permissionsDeleteSelected = '';
      this.permissionsDeleteData.forEach((el) => {
        if (parseInt(el.value) & parseInt(this.permissionsForm.permissions)){
          this.permissionsDeleteSelected = el.value;
        }
      }, this);

      this.permissionsWriteSelected = [];
      this.permissionsWriteData.forEach((el) => {
        if (parseInt(el.value) & parseInt(this.permissionsForm.permissions)){
          this.permissionsWriteSelected.push(el.value);
        }
      }, this);

      this.permissionsOtherSelected = [];
      this.permissionsOtherData.forEach((el) => {
        if (parseInt(el.value) & parseInt(this.permissionsForm.permissions)){
          this.permissionsOtherSelected.push(el.value);
        }
      }, this);
    },
    saveUserPermissions() {
      this.permissionsForm.action = "updateOEXPublicFolderUser";
      this.permissionsForm.token = localStorage.id_token;
      postRequest(this.permissionsForm).then((res) => {
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            res.returndata
          );
          this.showPermissions();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    removeUserPermissions() {
      postRequest({
        action: "removeOEXPublicFolderUser",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        folderid: this.activeItem.folderid,
        member_id: this.permissions[this.selectedIndex].memberID,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            res.returndata
          );
          this.showPermissions();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    showAddOwner() {
      this.allDomainUsers = [];
      this.selectedFolderOwners = [];
      postRequest({
        action: "getAllUsersDomain",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        offset: 0,
        limit: 0,
        sorting: 0,
        slicing: 0,
        getOTPAndToken: 0,
        showQuota: 0,
      }).then((res) => {
        if (res.returncode) {
          res.returndata.forEach(el => {
            if (el.account_type == 3) {
              this.allDomainUsers.push({value: el.email, text: el.email, username: el.username});
            }
          });
          this.$refs["addOwner"].show();
        } else {
          this.makeToastVariant("danger", res.returndata);
          this.allDomainUsers = [];
        }
      });
    },
    addOwners(){
      if (this.selectedFolderOwners.length > 0) {
        var itemsProcessed = 0;
        var totalItems = this.selectedFolderOwners.length;
        this.selectedFolderOwners.forEach(async (el) => {
          await postRequest({
            action: "addOEXPublicFolderUser",
            token: localStorage.id_token,
            domain: this.selectDomain.selectedDomain.domain,
            folderid: this.activeItem.folderid,
            username: el.username,
            permissions: "2043",
          }).then((res) => {
            itemsProcessed++;
            if (res.returncode) {
              this.makeToastVariant(
                "success",
                res.returndata
              );
            } else {
              this.makeToastVariant("danger", res.returndata);
            }
            if (itemsProcessed == totalItems) {
              this.showPermissions();
              this.confirmModalClose("addOwner");
            }
          })
        });
      }
    },
    saveActiveItem(item) {
      this.activeItem = item;
    },
    deleteFolder(){
      let folder = this.activeItem;
      postRequest({
        action: this.isFolder ? "deleteOEXPublicFolder" : "deleteOEXPublicSubFolder",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        folderid: folder.folderid,
        pfname: this.isFolder ? folder.name : "",
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            this.$t("COMMON.WAIT_MESSAGE")
          );
          this.getPublicFolders(
            this.selectDomain.selectedDomain.domain
          );
          this.confirmModalClose("delConfirm-modal");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getPublicFolders(newValue.domain);
    },
  },
};
</script>
